/* Section 11 */
#bens-section-11 {
  padding: 1rem 0 6rem 0;
  position: relative;
  z-index: 1;
}

.section-11-title {
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to right,
      var(--color-blue) 0%,
      var(--color-green) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin: 1rem auto 4rem auto;
}

.section-11-box {
  display: flex;
  flex-wrap: wrap;
  flex: 100%;
}

.section-11-box>.box-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex: calc(100% / 3);
  padding: 0.5rem;
  max-width: 22rem;
  margin: 0.5rem auto;
}

.section-11-box>.box-col>.box-img {
  object-fit: contain;
  margin: 0 auto 1rem auto;
}

.section-11-box>.box-col>.box-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0.5rem auto;
  color: var(--color-blue);
  -webkit-text-fill-color: var(--color-blue);
}

.section-11-box>.box-col>.box-desc {
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {
  .section-11-box>.box-col {
    flex: 100%;
  }
}

/* md */
@media (max-width: 768px) {
  .section-11-box>.box-col {
    flex: 100%;
  }
}

/* sm */
@media (max-width: 640px) {
  #bens-section-11 {
    padding: 3rem 1rem;
  }

  .section-11-box>.box-col {
    flex: 100%;
  }
}