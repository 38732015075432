/* Section 13 */
#bens-section-13 {
  padding: 3rem 0;
}

.bens-section-13-title {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.6;
  background: linear-gradient(to right,
      var(--color-blue) 0%,
      var(--color-green) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 1rem auto;
  flex: 100%;
}

.bens-section-13-subtitle {
  font-size: 1.5rem;
  flex: 100%;
  text-align: center;
  margin: 1rem auto 0.5rem auto;
  color: var(--color-blue);
}

.bens-section-13-desc {
  margin: 0.5rem auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bens-section-13-desc>li::before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 1.25em;
  margin-left: -1em;
  color: var(--color-blue);
}

.bens-section-13-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 100%;
}

.bens-section-13-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;
  width: 100%;
}

.bens-section-13-item>img {
  margin-right: 0.5rem;
  height: 0.9rem;
}

.bens-section-13-item .item-phone,
.bens-section-13-item .item-mail {
  text-decoration: none;
  color: var(--color-dark);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-13-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-13-btn {
  text-decoration: none;
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 0.5rem 3rem;
  border-radius: 1rem 2rem 1rem 1rem;
  margin: 1rem;
  transition: all 0.2s linear;
}

.section-13-btn:hover,
.section-13-btn:focus,
.section-13-btn:active {
  text-decoration: none;
  color: var(--color-white);
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {}

/* md */
@media (max-width: 768px) {}

/* sm */
@media (max-width: 640px) {
  #bens-section-13 {
    padding: 3rem 1rem;
  }

  .bens-section-13-desc {
    padding: 0;
  }

  .section-13-row {
    flex-direction: column;
  }
}